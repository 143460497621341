<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import axios from "axios";
import dataDummy from "./dummy.json";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import loadingVue from "@/components/skeleton.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, Multiselect, loadingVue },
  page: {
    title: "Data Jenis Harga Kain",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      apiUrl: {
        cloth_category: process.env.VUE_APP_API_BASE_URL + "cloth-categories",
        color: process.env.VUE_APP_API_BASE_URL + "colors",
        size: process.env.VUE_APP_API_BASE_URL + "sizes",
        cloth: process.env.VUE_APP_API_BASE_URL + "clothes",
        clothPriceType: process.env.VUE_APP_API_BASE_URL + "cloth-price-types",
      },
      isLoading: false,
      setCLothCategory: [],
      setColor: [],
      setSize: [],
      sizeCloth: [],
      setClothPriceType: [],
      setFields: [
        {
          cloth_category_id: "",
          cloth_color: [],
        },
      ],
      clothColors: [],
      originClothColor: [],
      checkedColors: [],
      checkedSizes: [],
      selectAllColors: false,
      selectAllSizes: false,
      skus: {},
      stocks: {},
      isLoadingButton: false,
      rowCount: 6,
      globalInput: [],
      globalStock: {
        stock: null,
        minStock: null,
      },
      globalPrice: {
        minStock: null,
        clothPrice: []
      }
    };
  },
  mounted() {
    this.getClothCategory();
    // this.getColor();
    // this.getSize();
    this.getClothPriceType();
  },
  validations: {
    setFields: {
      $each: {
        cloth_category_id: {
          required,
        },
        cloth_color: {
          $each: {
            sku: {
              required,
            },
            cloth_size: {
              $each: {
                stock: {
                  required,
                },
                min_stock: {
                  required,
                },
                cloth_price: {
                  $each: {
                    price: {
                      required,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    // checkedColors: {
    //   required,
    //   minLength: minLength(1),
    // },
    // checkedSizes: {
    //   required,
    //   minLength: minLength(1),
    // },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    clothCategoryErrors() {
      return this.$v.setFields.$each;
    },
    // checkedColorErrors() {
    //   return this.$v.checkedColors;
    // },
    // checkedSizeErrors() {
    //   return this.$v.checkedSizes;
    // }
  },
  watch: {
    checkedColors: function(newVal) {
      this.clothColors.forEach((color) => {
        // If no colors are selected, enable all colors
        if (newVal.length === 0) {
          color.disabled = false;
        } else {
          color.disabled = !newVal.includes(color.id);
        }

        // Additionally, toggle sizes based on color's disabled state
        color.sizes.forEach((size) => {
          size.disabled = color.disabled;
        });
      });

      this.clothColors = JSON.parse(JSON.stringify(this.clothColors));
    },
    checkedSizes: function (newVal) {
      this.clothColors.forEach((color) => {
        color.sizes.forEach((size) => {
          // If no sizes are selected, show all sizes
          if (newVal.length === 0) {
            size.disabled = false;
          } else {
            size.disabled = !newVal.includes(size.id);
          }
        });
      });
      this.clothColors = JSON.parse(JSON.stringify(this.clothColors));
    },
  },
  methods: {
    disableColors(colorId) {
      this.clothColors.forEach((color) => {
        if (color.id !== colorId) {
          color.disabled = this.checkedColors.length > 0;
        }
      });
    },
    async getClothCategory() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.cloth_category}?limit=1000`)
          .then((response) => {
            this.setCLothCategory = response.data.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
      }
    },
    async getColor() {
      try {
        this.isLoading = true;
        await axios.get(`${this.apiUrl.color}?limit=1000`).then((response) => {
          response.data.data.forEach((data) => {
            this.setColor.push({
              id: data.id,
              name: data.name,
              code_hexa: data.code_hexa,
              disabled: false,
              sizes: [],
            });
          });

          this.getSize();

          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getSize() {
      try {
        this.isLoading = true;
        await axios.get(`${this.apiUrl.size}?limit=1000`).then((response) => {
          response.data.data.forEach(data => {
            this.setSize.push({
              id: data.id,
              name: data.name,
              minStock: null,
              disabled: false,
              clothPrice: this.setClothPriceType
            })
          })
          this.setColor.forEach(color => {
            this.clothColors.push({
              id: color.id,
              name: color.name,
              code_hexa: color.code_hexa,
              disabled: false,
              sizes: []
            })
          })
          this.clothColors.forEach(clothColor => {
            clothColor.sizes = this.setSize
          })
          this.clothColors = JSON.parse(JSON.stringify(this.clothColors))
          this.originClothColor = JSON.stringify(this.clothColors)
          // console.log(this.clothColors)
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },

    
    async getClothPriceType() {
      try {
        this.isLoading = true;
        await this.$http
          .get(`${this.apiUrl.clothPriceType}?limit=1000&order=sort`)
          .then((response) => {
            response.data.data.forEach(data => {
              this.setClothPriceType.push({
                id: data.id,
                name: data.name,
                price: null
              })
              this.globalPrice.clothPrice.push({
                id: data.id,
                name: data.name,
                price: null
              })
            })
            // this.setClothPriceType = response.data.data;
            this.isLoading = false;
            this.getColor()
          });
      } catch (error) {
        console.error(error);
      }
    },
    

    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let models = {
          cloth_category_id: this.setFields[0].cloth_category_id.id,
          cloth_color: []
        }
        this.clothColors.forEach((item, index) => {
          if (item && !item.disabled) {
            let clothColor = {
              color_id: item.id,
              cloth_size: []
            };
            item.sizes.forEach((size, indexSize) => {
              if (size && !size.disabled) {
                let clothSize = {
                  size_id: size.id,
                  stock: 0,
                  min_stock: parseInt(size.minStock),
                  cloth_price: []
                };

                size.clothPrice.forEach(clothColor => {
                  if (clothColor) {
                    let cloth_price = {
                      cloth_price_type_id: clothColor.id,
                      price: parseInt(clothColor.price),
                    }

                    clothSize.cloth_price.push(cloth_price)
                    
                  }
                });

                clothColor.cloth_size.push(clothSize);
              }
            });
            models.cloth_color.push(clothColor);
          }
        });

        // models.forEach
        // console.log(models)
        try {
          this.isLoading = true;
          await this.$http
            .post(`${this.apiUrl.cloth}`, models, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((resp) => {
              console.log(resp)
              this.isLoading = false;
              this.$router.push("/produk");
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      } else {
        this.$store.dispatch(
          "notification/error",
          "Harap Lengkapi Form Terlebih Dahulu!"
        );
      }
    },


    changeToGlobalPrice (szClothPrice, gbClothPrice) {
      szClothPrice.forEach((sizePrice, indexSizePrice) => {
        szClothPrice[indexSizePrice].price = gbClothPrice[indexSizePrice].price
      })
    },

    resetToGlobalPrice (szClothPrice) {
      szClothPrice.forEach(data => {
        data.price = 0
      })
      this.globalPrice.minStock = 0
      this.globalPrice.clothPrice.forEach(data => {
        data.price = 0
      })
    },

    setGLobalPrice () {
      this.clothColors.forEach(data => {
        if (this.checkedColors.length === 0 && this.checkedSizes.length === 0) {
          data.sizes.forEach(size => {
            size.minStock = this.globalPrice.minStock
            this.changeToGlobalPrice(size.clothPrice, this.globalPrice.clothPrice)
          })
        } else {
          if (this.checkedColors.length > 0) {
            this.checkedColors.forEach(ckColor => {
              if (data.id === ckColor) {
                data.sizes.forEach(size => {
                  if (this.checkedSizes.length > 0) {
                    this.checkedSizes.forEach(ckSize => {
                      if (size.id === ckSize) {
                        size.minStock = this.globalPrice.minStock
                        this.changeToGlobalPrice(size.clothPrice, this.globalPrice.clothPrice)
                      }
                    })
                  } else {
                    size.minStock = this.globalPrice.minStock
                    this.changeToGlobalPrice(size.clothPrice, this.globalPrice.clothPrice)
                  }
                })
              }
            })
          } else {
            data.sizes.forEach(size => {
              this.checkedSizes.forEach(ckSize => {
                if (size.id === ckSize) {
                  size.minStock = this.globalPrice.minStock
                  this.changeToGlobalPrice(size.clothPrice, this.globalPrice.clothPrice)
                }
              })
            })
          }
        }
      })
    },

    resetFormGloblaPrice () {
      this.clothColors.forEach(data => {
        data.sizes.forEach(size => {
          size.minStock = 0
          this.resetToGlobalPrice(size.clothPrice)
        })
      })
    }
    // setClothColor (e, color) {
    //   color.sizes = []
    //   if (e.target.checked) {
    //     this.clothColors.push(color)
    //   } else {
    //     this.clothColors = this.clothColors.filter(data => data.id !== color.id)
    //   }
    // },

    // setClothSize (e, size) {
    //   let sizes = this.setSize.filter(data => this.checkedSizes.includes(data.id))
    //   if (this.clothColors.length > 0) {
    //     if (e.target.checked) {
    //       this.clothColors.forEach(clothColor => {
    //         clothColor.sizes.push(sizes)
    //       })
    //     } else {
    //       this.clothColors.forEach(clothColor => {
    //         clothColor.sizes = clothColor.sizes.filter(data => data.id !== size.id)
    //       })
    //     }
    //   }
    // }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <div class="row" v-if="!isLoading">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-4 m-3">
            <form enctype="multipart/form-data" @submit.prevent="onSubmit">
              <div v-for="(field, index) in setFields" :key="index" id="parent">
                <b-row class="mb-3">
                  <b-col md="4">
                    <label for="select-cloth-category" class="fw-bold"
                      >Pilih Kategori Pakaian</label
                    >
                    <multiselect
                      :id="'select-cloth-category'"
                      v-model="field.cloth_category_id"
                      deselect-label="Tidak bisa dihapus"
                      track-by="name"
                      label="name"
                      placeholder="Pilih Salah Satu"
                      :options="setCLothCategory"
                      :searchable="true"
                      :allow-empty="false"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                    <p
                      v-if="clothCategoryErrors[index].cloth_category_id.$error"
                      class="text-danger"
                    >
                      Harap pilih kategori pakaian
                    </p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col>
                    <div for="" class="form-check-label fw-bold">Warna Pakaian</div>
                    <!-- <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="select-all-colors"
                        name="select-all-colors"
                        value="1"
                        v-model="selectAllColors"
                      />
                      <label
                        for="select-all-colors" style="font-weight: 400; margin-bottom: 0;"
                        class=""
                        >Pilih Semua Warna</label>
                    </div> -->
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col class="d-flex flex-wrap gap-2">
                    <div
                      class="form-check form-check-inline d-flex justify-content-start align-items-center gap-2 color-wrap"
                      v-for="(color, index) in setColor"
                      :key="color.id"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="`checkbox-${color.id}-${index}`"
                        v-model="checkedColors"
                        :name="`checkbox-${color.id}-${index}`"
                        :value="color.id"
                      />
                      <label
                        class="form-check-label" style="font-weight: 400; display: flex;"
                        :for="`checkbox-${color.id}-${index}`"
                      >
                        <div
                          class="color-indicator"
                          :style="{ background: `#${color.code_hexa}`, marginRight: '5px' }"
                        ></div>
                        <span class="">{{
                          color.name
                        }}</span>
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col>
                    <div for="" class="fw-bold">Pilih Ukuran Pakaian</div>
                    <!-- <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="select-all-sizes"
                        name="select-all-sizes"
                        value="1"
                        v-model="selectAllSizes"
                      />
                      <label
                        for="select-all-sizes" style="font-weight: 400; margin-bottom: 0;"
                        class=""
                        >Pilih Semua Ukuran</label
                      >
                    </div> -->
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col>
                    <div
                      class="form-check form-check-inline"
                      v-for="(size, index) in setSize"
                      :key="size.id"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="`checkbox-${size.id}-${index}`"
                        v-model="checkedSizes"
                        :name="`checkbox-${size.id}-${index}`"
                        :value="size.id"
                        style="text-align: right;"
                      />
                      <label
                        class="form-check-label"
                        :for="`checkbox-${size.id}-${index}`"
                      >
                        <span
                          class="bg-success px-1 fs-6 rounded fw-bold text-white"
                          >{{ size.name }}</span
                        >
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <div class="border-bottom border-secondary my-4"></div>

                <!-- start global price -->

                <div class="mb-3">
                  <table class="table table-hovered table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Min. Stock</th>
                        <template v-for="priceType in globalPrice.clothPrice">
                          <th>{{ priceType.name }}</th>
                        </template>
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>
                          <b-form-input v-model="globalPrice.minStock" placeholder="0" style="text-align: right;"></b-form-input>
                        </td> 
                        <td v-for="priceType in globalPrice.clothPrice">
                          <b-form-input v-model="priceType.price" placeholder="0" style="text-align: right;"></b-form-input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="my-3">
                    <b-button variant="success" @click="setGLobalPrice">Terapkan</b-button>
                    <b-button variant="warning" @click="resetFormGloblaPrice" style="margin-left: 5px;">Reset</b-button>
                  </div>
                </div>

                <!-- end global price -->

                <!-- Start lopping color berdasarkan warna yang di pilih -->
                <div class="mb-3" v-for="(clothColor, indexclothColor) in clothColors" :key="clothColor.id" :style="(clothColor.disabled ? 'display: none;' : '')">
                  <label for="" class="d-flex">
                    <div
                      class="indicator-color"
                      :style="{ background: `#${clothColor.code_hexa}`, marginRight: '5px' }"
                    ></div> {{ clothColor.name }}
                  </label>
                  <table class="table table-hovered table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Ukuran</th>
                        <th>Min. Stock</th>
                        <template v-for="priceType in setClothPriceType">
                          <th>{{ priceType.name }}</th>
                        </template>
                      </tr>
                    </thead>
                    <tbody >
                      <tr v-for="(size, indexSize) in clothColor.sizes" :key="size.id"  :style="(size.disabled ? 'display: none;' : '')">
                        <td>{{ size.name }}</td>
                        <td>
                          <b-form-input v-model="size.minStock" placeholder="0" :id="'color_' + clothColor.id + '_minStock_' + size.id"  :name="'color_' + clothColor.id + '_minStock_' + size.id" style="text-align: right;"></b-form-input>
                        </td> 
                        <td v-for="priceType in size.clothPrice" :key="priceType.id">
                          <b-form-input v-model="priceType.price" placeholder="0" :id="'color_' + clothColor.id + '_minStock_' + size.id + '_price_' + priceType.id"  :name="'color_' + clothColor.id + '_minStock_' + size.id + '_price_' + priceType.id" style="text-align: right;"></b-form-input>
                        </td> 
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- end lopping color berdasarkan warna yang di pilih -->
              </div>
              <b-button
                type="submit"
                variant="primary"
                :disabled="isLoadingButton"
                class="w-sm"
                ><b-spinner
                  style="width: 1rem; height: 1rem; margin-right: 5px"
                  label="Loading..."
                  v-if="isLoadingButton"
                ></b-spinner>
                Tambah Produk</b-button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-4 m-3">
            <b-row class="mb-4">
              <b-col>
                <loadingVue width="35%" height="3rem" borderRadius="7px" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <loadingVue width="30%" height="2.5rem" borderRadius="7px" />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col lg="2" class="my-2" v-for="row in rowCount" :key="row">
                <loadingVue width="100%" height="2rem" borderRadius="7px" />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <loadingVue width="30%" height="2.5rem" borderRadius="7px" />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col lg="2" class="my-2" v-for="row in rowCount" :key="row">
                <loadingVue width="100%" height="2rem" borderRadius="7px" />
              </b-col>
            </b-row>
            <div class="mt-4">
              <loadingVue width="10rem" height="2.5rem" borderRadius="7px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>